* {
    box-sizing: border-box;
}

body {
    margin: 0;
}
.nav{
    width: 100%;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    gap: 2rem;
    padding: 30px;
    height: 64px;
    font-size: 20px;
}

.navB{
    width: 100%;
    background-color: rgb(0, 0, 0);
    color: rgb(255, 255, 255);
    display: flex;
    align-items: center;
    gap: 2rem;
    padding: 30px;
    height: 100px;
    font-size: 30px;
}

.nav ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 2rem;
}

.navB ul {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    gap: 2rem;
}

.siteTitle {
    text-decoration: none;
    font-family: 'Humanistic';
    color: rgb(255, 255, 255);
    float: left
}

.siteTitle:hover{
    color: purple;
}

.title {
    text-decoration: none;    
    font-family: 'Humanistic';
    font-size: 20px;
    color: rgb(255, 255, 255);
}

.title:hover{
    color: purple;
}

.drawerHeader{
    background-color: black;
    display: flex;
    height: 64px;
    justify-content: space-between;
    align-items: center;
}

.drawerHeaderB{
    background-color: black;
    display: flex;
    height: 100px;
    justify-content: space-between;
    align-items: center;
}

.drawer{
    background-color: black;
}

.links{
    float: right;
    margin-left: auto;
    margin-right: 0;
}

.drawerIcon{
    float: right;
    margin-left: auto;
    margin-right: 0;
    width: 50px;
    height: 50px;    
}

.drawerLink{
    font-family: 'Humanistic';
    font-size: 20px;  
    text-decoration: none;
    color: black;
    margin: auto;
    text-align: center;
}

.drawerLink:hover{
    color: purple;
}

.linkContainer{
    margin-left: 10px;
    margin-top: 20px;
}